<template>
    <div class="margin-bottom-30">
        <Modal :title="title" v-model="isShowModal">
            <template v-slot:modal-content>
                <div v-if="modelValue?.key === 'content'" class="edit-wrapper">
                    <div class="edit-input">
                        <span>產險到期日</span>
                        <Textbox type="date" v-model="propertyDueDateValue" />
                    </div>
                    <div class="edit-input">
                        <span>車牌號碼</span>
                        <Textbox
                            type="text"
                            v-model="vehicleNumberValue"
                            placeholder="範例：ABC-1234"
                            :maxlength="8"
                            :isError="isError"
                            :errorMessage="errorMessage"
                            ref="vehicleNumberInput"
                        />
                    </div>
                </div>

                <textarea
                    v-if="modelValue?.key === 'note'"
                    class="textarea"
                    v-model="note"
                    placeholder="請輸入記事"
                />
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="cancel"
                    :disabled="isSaveLoading ? true : false"
                />
                <div :class="isSaveLoading ? 'loading' : ''">
                    <Button
                        buttonWord="儲存"
                        buttonStyle="blue"
                        @click="save"
                        class="save-button"
                    />
                    <div
                        v-if="isSaveLoading"
                        class="process-loading-icon"
                    ></div>
                </div>
            </template>
        </Modal>
        <SuccessModal v-model="isShowSuccessModal" message="儲存成功" />
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import Textbox from '@/components/Textbox.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import { editTiaInsuranceDetailAPI } from '@/assets/javascripts/api'

export default {
    name: 'InsuranceEditModal',
    components: {
        Button,
        Modal,
        Textbox,
        SuccessModal
    },
    emits: ['update:modelValue', 'update:edited'],
    props: {
        modelValue: {
            type: Object,
            default: function () {
                return { isShow: false }
            }
        },
        edited: {
            type: Object,
            default: function () {
                return {}
            }
        },
        insId: {
            type: String,
            default: function () {
                return ''
            }
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        accountManCode: {
            type: [String, Number],
            default: ''
        }
    },
    methods: {
        turnOffModal: function () {
            this.$emit('update:modelValue', {
                key: this.modelValue.key,
                isShow: false
            })
        },
        cancel: function () {
            this.turnOffModal()
        },
        save: async function () {
            this.$setGaEvent('saveModification', 'click-Button', {
                params: 'detail'
            })
            const propertyDueDate = this.propertyDueDateValue ?? ''
            const vehicleNumber = this.transVehicleNumberValue(
                this.vehicleNumberValue
            )

            if (!this.checkFormat(vehicleNumber)) {
                this.isError = true
                this.vehicleNumberValue = this.transVehicleNumberValue(
                    this.vehicleNumberValue
                )
                return
            } else this.isError = false

            const payload = {
                code: this.insId,
                note: this.note,
                propertyDueDate: propertyDueDate,
                vehicleNumber: vehicleNumber,
                manCode: this.accountManCode //用於admin編輯
            }
            try {
                this.isSaveLoading = true
                const res = await editTiaInsuranceDetailAPI(payload)
                if (res.status !== 200) throw res.data.code
                this.note = this.tempNote
                this.$emit('update:edited', payload)
                this.turnOffModal()
                this.isShowSuccessModal = true
                const editedValue = {}
                editedValue[this.insId] = {
                    propertyDueDate: propertyDueDate,
                    vehicleNumber: vehicleNumber
                }
                this.$store.commit('set', {
                    detailEditedList: {
                        ...this.$store.state.detailEditedList,
                        ...editedValue
                    }
                })
            } catch (error) {
                this.turnOffModal()
                this.$showErrorMessage(
                    '儲存時發生錯誤，請稍後再試',
                    '如持續發生此情形，請聯絡資訊客服'
                )
            }
            setTimeout(() => {
                this.isSaveLoading = false
            }, 800)
        },
        transVehicleNumberValue: function (value) {
            if (typeof value === 'string') {
                return this.$stringFullToHalf(
                    value.toLocaleUpperCase()
                ).replace(/^\s+|\s+$/g, '')
            }

            event.target.value = event.target.value
                ? this.$stringFullToHalf(
                      event.target.value.toLocaleUpperCase()
                  ).replace(/^\s+|\s+$/g, '')
                : event.target.value

            this.isError = false
        },
        checkFormat: function (str) {
            let value = str
            if (value === undefined) value = ''
            const regex = /^([\u4e00-\u9fa5A-Z0-9])+([A-Z0-9-])+([A-Z0-9])$/
            return value === '' || regex.test(value)
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue?.isShow
            },
            set(val) {
                if (!val) this.isError = false
                this.$emit('update:modelValue', {
                    key: this.modelValue.key,
                    isShow: val
                })
                this.note = this.dataList.note
                this.propertyDueDateValue = this.dataList.propertyDueDate
                this.vehicleNumberValue = this.dataList.vehicleNumber
            }
        },
        title: function () {
            return this.modelValue.key === 'content'
                ? '保單內容編輯'
                : '記事編輯'
        }
    },
    watch: {
        dataList: function () {
            this.remark = this.dataList.remark
            this.note = this.dataList.note
            this.propertyDueDateValue = this.dataList.propertyDueDate
            this.vehicleNumberValue = this.dataList.vehicleNumber
        },
        isShowModal: function (newVal) {
            if (!newVal) {
                this.note = this.dataList.note
                this.propertyDueDateValue = this.dataList.propertyDueDate
                this.vehicleNumberValue = this.dataList.vehicleNumber
            }
            if (this.modelValue.key === 'content')
                if (this.isShowModal) {
                    this.$nextTick(() => {
                        const vehicleNumberInput = this.$refs.vehicleNumberInput
                            .$el
                        const input = vehicleNumberInput.querySelector('input')
                        input.addEventListener(
                            'input',
                            this.transVehicleNumberValue
                        )
                    })
                } else {
                    const vehicleNumberInput = this.$refs.vehicleNumberInput.$el
                    const input = vehicleNumberInput.querySelector('input')
                    input.removeEventListener(
                        'input',
                        this.transVehicleNumberValue
                    )
                }
        }
    },
    data() {
        return {
            isShowSuccessModal: false,
            note: this.dataList.note,
            propertyDueDateValue: this.dataList.propertyDueDate,
            vehicleNumberValue: this.dataList.vehicleNumber,
            isSaveLoading: false,
            isError: false,
            errorMessage: '車牌格式錯誤，請重新輸入'
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-input {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:nth-child(1) {
        margin-top: 10px;
    }
    &:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    span {
        display: inline-block;
        min-width: 80px;
        color: $placeholder-black;
        font-size: 14px;
        margin-right: 10px;
    }
    :deep(.input-container) {
        margin-bottom: 0px;
        @media screen and (max-width: 576px) {
            width: 100%;
        }
    }
}

.textarea {
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid $fourth-grey;
    font-size: 14px;
    font-family: $primary-cn;
    color: $sixth-black;
    background-color: $primary-white;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    resize: none;

    &.placeholder {
        color: $placeholder-black;
    }

    &:focus {
        border: 1px solid $third-blue;
    }
}

::-webkit-input-placeholder {
    color: $placeholder-black;
}

:-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-ms-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::placeholder {
    color: $placeholder-black;
}

.loading {
    position: relative;
    width: 68px;
    .save-button {
        visibility: hidden;
    }
    .process-loading-icon {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 8px;
        right: 25px;
    }
}
</style>
